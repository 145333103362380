import React, { Component } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link, useHistory } from 'react-router-dom';
import Sticky from 'react-stickynode';
import data from '../../Data.json';

function Header2() {
  const history = useHistory();
  const [hideBtn, setHideBtn] = useState(false);
  const options = [
    { page: 'Home', link: 'home' },
    { page: 'About Us', link: 'about-us' },
    { page: 'Mission,Vision & Values', link: 'mission' },
    { page: 'Corporate Information', link: 'corporate' },
    { page: 'Board Of Directors', link: 'board-of-directors' },
    // { page: 'Nomination & Remuneration', link: 'nominatiom-and-renumeration' },
    // { page: 'Audit Commitee', link: 'audit-commitee' },
    { page: 'Key Management', link: 'key-management' },
    // { page: 'Sister Concern Of JSRM', link: 'sister-concern' },
    { page: 'Our Products', link: 'products' },
    { page: 'Quality Test', link: 'quality-test' },
    { page: 'Quality Control', link: 'quality-control' },
    { page: 'Certifications', link: 'certification' },
    { page: 'Pre Sales Service', link: 'pre-sales-service' },
    { page: 'Post Sales Service', link: 'post-sales-service' },
    { page: 'Supply Chain', link: 'supply-chain' },
    { page: 'Environmental', link: 'environmental' },
    { page: 'Remarkable Customers', link: 'remarkable-customers' },
    // { page: 'Financial', link: 'financial' },
    { page: 'Social', link: 'social' },
    { page: 'Technological', link: 'technological' },
    { page: 'Compliance', link: 'compliance' },
    { page: 'Hr Policy', link: 'hr-policy' },
    { page: 'Available Jobs', link: 'available-jobs' },
    { page: 'Events & Publications', link: 'news-events' },
    // { page: 'Brochure', link: 'brochure' },
    // { page: 'Leaflet', link: 'leaflet' },
    { page: 'JSRM Journey', link: 'journey' },
    { page: 'Television Commercials', link: 'tvc' },
    // { page: 'Photo Gallery', link: 'gallery' },
    { page: 'Contact Us', link: 'contact-1' },
    { page: '', link: 'news' },
    { page: 'Online CV', link: 'onlinecv' },
    { page: 'Order Now', link: 'loginPage' },
  ];

  const [query, setQuery] = React.useState('');
  const [selected, setSelected] = useState([]);
  const [active, setActive] = useState(false);
  useEffect(() => {
    var btn = document.querySelector('.navicon');
    var nav = document.querySelector('.header-nav');

    function toggleFunc() {
      btn.classList.toggle('open');
      nav.classList.toggle('show');
    }

    btn.addEventListener('click', toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll('.header-nav > ul > li')
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener('click', function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      const active = current.classList.contains('open');
      navUl.forEach((el) => el.classList.remove('open'));
      //current.classList.add('open');

      if (active) {
        current.classList.remove('open');
        console.log('active', active);
      } else {
        current.classList.add('open');
        console.log('close');
      }
    }
  }, []);

  // state = {
  //   searchText: "Home"
  // };

  const handleRoute = (route) => () => {
    this.props.history.push({ pathname: route });
  };

  const handleSearchInput = (event) => {
    console.log('searched', event.target.value);
    setQuery(event.target.value);
  };
  const handleSearchfind = (event) => {
    console.log('founded', event.target.value);
  };
  useEffect(() => {
    console.log('query', query);
  }, [query]);

  const handleSearchSubmit = (event) => {};

  useEffect(() => {
    console.log('selected', selected);
    if (selected.length) {
      history.push(`/${selected[0].link}`);
    }
  }, [history, selected]);

  return (
    <>
      <header className="site-header mo-left header ext-header navstyle1">
        {/* <div className="top-bar">
                    <div className="container">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="dlab-topbar-left">
                                <ul>
                                    <li><Link to="/about-1">About Us</Link></li>
                                    <li><Link to="/privacy-policy">Refund Policy</Link></li>
                                    <li><Link to="/help-desk">Help Desk</Link></li>
                                </ul>
                            </div>
                            <div className="dlab-topbar-right">
                                <Link to="/contact-1" className="site-button radius-no btnhover11">GET A QUOTE</Link>						
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="middle-bar bg-white">
          <div className="container">
            <div className="middle-area">
              <div className="logo-header">
                <Link to="/">
                  <img src={require('../../images/logo2.png')} alt="" />
                </Link>
              </div>
              <div className="service-list">
                <ul>
                  <li>
                    <i className="la la-phone"></i>
                    <a href={`tel:01409998242`}>
                      <h4 className="title">01409998242</h4>
                    </a>
                    <span>Call</span>
                  </li>
                  <li>
                    <i className="la la-clock-o"></i>
                    <h4 className="title">09:00 AM - 06:00 PM</h4>
                    <span>Sat - Thurs</span>
                  </li>
                  <li>
                    <i className="la la-map"></i>
                    <h4 className="title">
                      B-342, Enaetnagar, Godnail, Siddhirganj
                    </h4>
                    <span>Narayanganj-1432, Bangladesh</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Sticky innerZ={999} enabled={true}>
          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar clearfix ">
              <div className="container clearfix">
                <div className="logo-header mostion">
                  <Link to="/">
                    <img src={require('../../images/logo2.png')} alt="" />
                  </Link>
                </div>

                <button
                  className="navbar-toggler collapsed navicon justify-content-end"
                  type="button"
                  onClick={
                    ()=>{
                      setHideBtn(!hideBtn)
                    
                    }
                  }
                  // data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div className="extra-nav">
                  <div className="extra-cell">
                    {/* <button
                        id="quik-search-btn"
                        type="button"
                        className="site-button-link"
                      >
                        <i className="la la-search"></i>
                      </button> */}
                   {
                    hideBtn ==false ?(
                      <Form inline >
                      <div style={{ display: 'flex' ,zIndex:'0'}}>
                        {/* <FormControl
              onChange={handleSearchInput}
              
              type="text"
              placeholder="Search"
              className="mr-sm-2"
            /> */}
                        <Typeahead
                          id="basic-example"
                          labelKey={(option) => `${option.page}`}
                          onChange={setSelected}
                          options={options}
                          placeholder="Search A Page.."
                          selected={selected}
                          minLength={1}
                        />
                        <Button
                          onClick={handleSearchSubmit}
                          variant="outline-info"
                          className="ml-2"
                        >
                          <i className="la la-search"></i>
                        </Button>
                      </div>
                    </Form>
                    ):null
                   }
                  </div>
                </div>

                {/* <div className="dlab-quik-search ">
                    <form action="#">
                      <input
                        name="search"
                        value=""
                        type="text"
                        className="form-control"
                        placeholder="Type to search"
                      />
                      <span id="quik-search-remove">
                        <i className="ti-close"></i>
                      </span>
                    </form>
                  </div> */}

                <div
                  className="header-nav navbar-collapse collapse justify-content-start"
                  id="navbarNavDropdown"
                >
                  <div className="logo-header d-md-block d-lg-none">
                    <Link to="/">
                      <img src={require('../../images/logo2.png')} alt="" />
                    </Link>
                  </div>
                  <ul className="nav navbar-nav">
                    <li className=" has-mega-menu homedemo">
                      {' '}
                      <Link to="/">Home</Link>
                      {/* <ul className="mega-menu">
                                                <li><Link to="/"><img src={require("../../demo/pic1.jpg")} alt=""/><span>Home - Default</span></Link></li>
                                                <li><Link to="/index-2"><img src={require("../../demo/pic2.jpg")} alt=""/><span>Home - Oil/Gas Plant</span></Link></li>
                                                <li><Link to="/index-3"><img src={require("../../demo/pic3.jpg")} alt=""/><span>Home - Steel Plant</span></Link></li>
                                                <li><Link to="/index-4"><img src={require("../../demo/pic4.jpg")} alt=""/><span>Home - Factory</span></Link></li>
                                                <li><Link to="/index-5"><img src={require("../../demo/pic5.jpg")} alt=""/><span>Home - Construct</span></Link></li>
                                                <li><Link to="/index-6"><img src={require("../../demo/pic6.jpg")} alt=""/><span>Home - Solar Plant</span></Link></li>
                                                <li><Link to="/index-7"><img src={require("../../demo/pic7.jpg")} alt=""/><span>Home - Food Industry</span></Link></li>
                                                <li><Link to="/index-8"><img src={require("../../demo/pic8.jpg")} alt=""/><span>Home - Agriculture</span></Link></li>
                                                <li><Link to="/index-9"><img src={require("../../demo/pic9.jpg")} alt=""/><span>Home - Ship Industry</span></Link></li>
                                                <li><Link to="/index-10"><img src={require("../../demo/pic10.jpg")} alt=""/><span>Home - Leather Industry</span></Link></li>
                                                <li><Link to="/index-11"><img src={require("../../demo/pic11.jpg")} alt=""/><span>Home - Nuclear Plant</span></Link></li>
                                                <li><Link to="/index-12"><img src={require("../../demo/pic12.jpg")} alt=""/><span>Home - Beer Factory</span></Link></li>
                                                <li><Link to="/index-13"><img src={require("../../demo/pic13.jpg")} alt=""/><span>Home - Mining Industry</span></Link></li>
                                                <li><Link to="/index-14"><img src={require("../../demo/pic14.jpg")} alt=""/><span>Home - Car Industry</span></Link></li>
                                                <li><Link to="/index-15"><img src={require("../../demo/pic15.jpg")} alt=""/><span>Home - Plastic Industry</span></Link></li>
                                            </ul> */}
                    </li>

                    <li>
                      {' '}
                      <Link to={'#'}>
                        About Us<i className="fa fa-chevron-down"></i>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={'/about-us'}>About Us</Link>

                          <li>
                            <Link to={'/mission'}>
                              Mission ,Vision & Values
                            </Link>
                          </li>
                          <li>
                            <Link to={'/corporate'}>Corporate Information</Link>
                          </li>
                          <li>
                            <Link to={'/board-of-directors'}>
                              Board Of Directors
                            </Link>
                          </li>
                          {/* <li>
                            <Link to={'/nominatiom-and-renumeration'}>
                              Nomination & Remuneration
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link to={'/audit-commitee'}>Audit Commitee</Link>
                          </li> */}
                          <li>
                            <Link to={'/key-management'}>Key Management</Link>
                          </li>
                          {/* <li>
                            <Link to={'/sister-concern'}>
                              Sister Concern Of JSRM
                            </Link>
                          </li> */}
                        </li>
                        {/* <li>
													<Link to={'#'}>Pages</Link>
													<ul>
														<li><Link to={'/team-1'}>Team 1</Link></li>
														<li><Link to={'/team-2'}>Team 2</Link></li>
														<li><Link to={'/faq-1'}>Faqs 1</Link></li>
														<li><Link to={'/faq-2'}>Faqs 2</Link></li>
														<li><Link to={'/portfolio-grid-2'}>portfolio Grid 2</Link></li>
													</ul>
												</li>
												<li>
													<Link to={'#'}>Pages</Link>
													<ul>
														<li><Link to={'/portfolio-grid-3'}>Portfolio Grid 3</Link></li>
														<li><Link to={'/portfolio-grid-4'}>Portfolio Grid 4</Link></li>
														<li><Link to={'/portfolio-details'}>Portfolio Details</Link></li>
														<li><Link to={'/error-403'}>Error 403</Link></li>
													</ul>
												</li>
												<li>
													<Link to={'#'}>Pages</Link>
													<ul>
														<li><Link to={'/error-404'}>Error 404</Link></li>
														<li><Link to={'/error-405'}>Error 405</Link></li>
														<li><Link to={'/help-desk'}>Help Desk</Link></li>
														<li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
													</ul>
												</li> */}
                      </ul>
                    </li>
                    <li className="has-mega-menu">
                      {' '}
                      <Link to={'#'}>
                        Products & Services
                        <i className="fa fa-chevron-down"></i>
                      </Link>
                      <ul className="mega-menu">
                        <li>
                          <Link to={'#'}>Products</Link>
                          <ul>
                            <li>
                              <Link to={'/products'}>Our Products</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to={'#'}>Quality Assurance</Link>
                          <ul>
                            <li>
                              <Link to={'/quality-test'}>Quality Test</Link>
                            </li>
                            <li>
                              <Link to={'/quality-control'}>
                                Quality Control
                              </Link>
                            </li>
                            <li>
                              <Link to={'/certification'}>Certifications</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to={'#'}>Services</Link>
                          <ul>
                            <li>
                              <Link to={'/pre-sales-service'}>
                                Pre Sales Service
                              </Link>
                            </li>
                            <li>
                              <Link to={'/post-sales-service'}>
                                Post Sales Service
                              </Link>
                            </li>
                            <li>
                              <Link to={'/supply-chain'}>Supply Chain</Link>
                            </li>
                          </ul>
                        </li>

                        {/* <li>
													<Link to={'#'}>Pages</Link>
													<ul>
														<li><Link to={'/team-1'}>Team 1</Link></li>
														<li><Link to={'/team-2'}>Team 2</Link></li>
														<li><Link to={'/faq-1'}>Faqs 1</Link></li>
														<li><Link to={'/faq-2'}>Faqs 2</Link></li>
														<li><Link to={'/portfolio-grid-2'}>portfolio Grid 2</Link></li>
													</ul>
												</li>
												<li>
													<Link to={'#'}>Pages</Link>
													<ul>
														<li><Link to={'/portfolio-grid-3'}>Portfolio Grid 3</Link></li>
														<li><Link to={'/portfolio-grid-4'}>Portfolio Grid 4</Link></li>
														<li><Link to={'/portfolio-details'}>Portfolio Details</Link></li>
														<li><Link to={'/error-403'}>Error 403</Link></li>
													</ul>
												</li>
												<li>
													<Link to={'#'}>Pages</Link>
													<ul>
														<li><Link to={'/error-404'}>Error 404</Link></li>
														<li><Link to={'/error-405'}>Error 405</Link></li>
														<li><Link to={'/help-desk'}>Help Desk</Link></li>
														<li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
													</ul>
												</li> */}
                      </ul>
                    </li>
                    <li>
                      <Link to="#">
                        Sustainability<i className="fa fa-chevron-down"></i>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/environmental">Environmental</Link>
                        </li>
                        <li>
                          <Link to="/remarkable-customers">
                            Remarkable Customers
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/financial">Financial</Link>
                        </li> */}
                        <li>
                          <Link to="/social">Social</Link>
                        </li>
                        <li>
                          <Link to="/technological">Technological</Link>
                        </li>
                        <li>
                          <Link to="/compliance">Compliance</Link>
                        </li>
                      </ul>
                    </li>
                    {/* <i className="fa fa-chevron-down"></i> */}
                    <li>
                      <Link to="#">
                        News & Media<i className="fa fa-chevron-down"></i>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/news-events">Events & Publications</Link>
                        </li>
                        {/* <li>
                          <Link to="/brochure">Brochure</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/leaflet">Leaflet</Link>
                        </li> */}
                        <li>
                          <Link to="/journey">JSRM Journey</Link>
                        </li>
                        <li>
                          <Link to="/tvc">Television Commercials</Link>
                        </li>
                        {/* <li>
                          <Link to="/gallery">Photo Gallery</Link>
                        </li> */}
                      </ul>
                    </li>
                    <li className=" has-mega-menu homedemo">
                      {' '}
                      <Link to="/gallery">Photo Gallery</Link>
                    </li>
                    <li>
                      <Link to="#">
                        Career<i className="fa fa-chevron-down"></i>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/hr-policy">HR Policy</Link>
                        </li>
                        <li>
                          <Link to="/available-jobs">Available Jobs</Link>
                        </li>
                        <li>
                          <Link to="/onlinecv">Online CV</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/contact-1">
                        Contact Us<i></i>
                      </Link>
                      {/* <ul className="sub-menu">
                                                <li><Link to="/contact-1">Contact us 1</Link></li>
                                                <li><Link to="/contact-2">Contact us 2</Link></li>
                                                <li><Link to="/contact-3">Contact us 3</Link></li>
                                                <li><Link to="/contact-4">Contact us 4</Link></li>
                                            </ul> */}
                    </li>
                    <li>
                      <Link to="/login-page">
                        Order Now <i></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
      </header>
    </>
  );
}
export default Header2;
